import React, {Component} from 'react';
import {graphql} from 'react-apollo';
import {observer} from "mobx-react";
import classnames from 'classnames';
import gql from 'graphql-tag';
import {API, ensurePluginsLoaded} from "../../Plugins";
import {Route, Switch, Link} from 'react-router-dom';
import WidgetInstallPane from "./WidgetInstallPane";
import WidgetRecipientsPane from "./WidgetRecipientsPane";
import './WidgetSettings.css';


type Props = {
  widget: any;
  data: any;
  match: any;
  className?: string
};


const withQuery = graphql(gql`
  query($id: ID!) {
    widget(id: $id) {
      id,
      name,
      operators {
        user {
          id,
          email,
        },
        role
      },
      plugins {
        name,
        adminExtensionUrl
      }
    }
  }
`, {
  options: ({ match }: any) => ({ variables: { id: match.params.id } }),
});

export const WidgetSettings = withQuery(observer(class WidgetSettings extends Component<Props> {
  componentDidUpdate() {
    if (this.props.data.widget) {
      let urls = this.props.data.widget.plugins.map((p: any) => p.adminExtensionUrl);
      urls = urls.filter((u: any) => !! u);
      ensurePluginsLoaded(urls);
    }
  }

  render() {
    if (this.props.data.loading) {
      return null;
    }
    if (this.props.data.error) {
      return 'Error';
    }
    if (!this.props.data.widget) {
      return "Widget not found."
    }

    const widget = this.props.data.widget;
    const match = this.props.match;
    const pluginSet = API.getPluginSetForWidget(widget);
    const panes: any = pluginSet.settingsPanes;

    const allPanes = [
      {
        label: 'Instructions',
        component: WidgetInstallPane,
        url: 'instructions'
      },
      {
        label: 'Recipients',
        component: WidgetRecipientsPane,
        url: 'recipients'
      },
      ...panes
    ];

    return (
      <div className={classnames("WidgetSettings", this.props.className)}>
        <h2>Widget: {widget.name}</h2>

        <div className="WidgetSettings-content">
          <ul className="WidgetSettings-navigation">
            {
              allPanes.map(pane => {
                return <li key={pane.url}><Link to={`${match.url}/${pane.url}`}>
                  {pane.label}
                </Link></li>
              })
            }
          </ul>

          {/*className="WidgetSettings-paneContainer"*/}
          <Switch>
            {allPanes.map(pane =>
              <Route
                key={pane.url}
                path={`${match.path}/${pane.url}`}
                render={() => <pane.component widget={widget} />}
              />)
            }
            <Route
              exact
              path={match.path}
              render={() => <WidgetInstallPane widget={widget} />}
            />)
          </Switch>
        </div>
      </div>
    );
  }
}));

