import {observable, computed, extendObservable} from "mobx";
import {loadScript} from "./utils";


/**
 * Make sure all the given plugin urls are loaded.
 */
export function ensurePluginsLoaded(pluginUrls: any[]) {
  pluginUrls.map(url => loadScript(url));
}


type SettingsPane = {
  label: string;
  component: any;
  url: string;
}


class Plugin {
  _settingsPanes: any|SettingsPane[];

  constructor() {
    this._settingsPanes = observable.array();
  }

  registerSettingsPane(label: string, id: string, component: any) {
    this._settingsPanes.push({
      label,
      component,
      url: id
    })
  }
}


class PluginSet {
  plugins: Plugin[];

  constructor(plugins: Plugin[]) {
    this.plugins = plugins;

    extendObservable(this, {
      settingsPanes: computed(this.settingsPanes)
    })
  }

  settingsPanes() {
    const panes: SettingsPane[] = [];
    for (const plugin of this.plugins) {
      panes.push.apply(panes, plugin._settingsPanes);
    }
    return panes;
  }

  // getWidgets(location: string) {
  //   return computed(() => {
  //     for (const plugin of this.plugins) {
  //
  //     }
  //   });
  // }
}


// For now this is global, so plugins when loaded can call into it.
// Is it possible that we call into the plugins instead?
class PluginAPI {
  plugins: Map<string, Plugin>|any;

  constructor() {
    this.plugins = observable.map();
  }

  registerPlugin(name: string, pluginFunc: any) {
    const plugin = new Plugin();
    pluginFunc(plugin);
    this.plugins.set(name, plugin);
  }

  // Return a set of plugins, usually as activated in a particular widget.
  getPluginSet(pluginNames: string[]) {
    return computed(() => {
      const plugins = [];
      for (const pluginName of pluginNames) {
        const plugin = this.plugins.get(pluginName);
        if (!plugin) {
          console.warn(`The plugin "${pluginName}" is associated with the widget, but has not been loaded. Ignoring.`)
          continue;
        }
        plugins.push(plugin);
      }
      return new PluginSet(plugins);
    }).get();
  }

  // Assumes a widget from the graph
  getPluginSetForWidget(widget: any) {
    return this.getPluginSet(widget.plugins.map((p: any) => p.name));
  }
}


const API = new PluginAPI();
export {API};


// Setup the plugin API on the window object.
export function installPluginAPI() {
  // @ts-ignore
  window.FBWADMIN = API;
}