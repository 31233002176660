import React, {Component} from 'react';
import {Screen} from '../screen';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {Route, withRouter} from 'react-router-dom';
import {Button} from '../../components';
import {WidgetList} from './WidgetList';
import {WidgetSettings} from './WidgetSettings';
import './WidgetScreen.css';



const WidgetQuery = gql`query {
  viewer {
    widgets {
      id,
      name,
      chats { count }
    }
  }
}`;
const withQuery = graphql(WidgetQuery);


const withCreateWidget = graphql(gql`
  mutation($name: String!) {
    createWidget(name: $name) {
      widget {
        id,
        name,
        chats { count }
      }
    }
  }
`);


type Props = {
  data: any;
  mutate: any;
  history: any;
}
type State = {
  isAddingNewWidget?: boolean
}


export const WidgetScreen =
withQuery(
withCreateWidget(
withRouter(
//@ts-ignore
class WidgetScreen extends Component<Props, State> {

  state: State = {};

  render() {
    if (this.props.data.loading) {
      return null;
    }

    return (
      <Screen
        className="WidgetsScreen"
        currentPageLabel="Widgets"
        buttons={<div>
          <Button iconName="add" onClick={this.handleAddNewClick}>
            Add a widget
          </Button>
        </div>}
      >
        <div className="WidgetsScreen-Content">
          <WidgetList
            className="WidgetsScreen-WidgetList"
            widgets={this.props.data.viewer.widgets}
            onClick={this.handleSelect}

            addNewMode={this.state.isAddingNewWidget || false}
            onAddNewComplete={this.handleAddNewModeCompleted}
            onAddNewAbort={this.handleAddNewModeAborted}
          />
          <div className="WidgetsScreen-WidgetPane">
            <Route path="/widgets/:id" component={WidgetSettings}/>
          </div>
        </div>
      </Screen>
    );
  }

  handleAddNewClick = () => {
    this.setState({isAddingNewWidget: true});
  };

  handleAddNewModeCompleted = async (name: string) => {
    const {data} = await this.props.mutate({
      variables: { name },
      update: (proxy: any, { data: { createWidget } }: any) => {
        const data = proxy.readQuery({ query: WidgetQuery });
        data.viewer.widgets.splice(0, 0, createWidget.widget);
        proxy.writeQuery({ query: WidgetQuery, data });
      },
    });
    this.setState({isAddingNewWidget: false});
    this.props.history.push(`/widgets/${data.createWidget.widget.id}`);
  };

  handleAddNewModeAborted = () => {
    this.setState({isAddingNewWidget: false});
  };

  handleSelect = (id: string) => {
    this.props.history.push(`/widgets/${id}`);
  }
})));