import T from 'prop-types';
import {ApolloProvider} from 'react-apollo';
import {ApolloClient} from 'apollo-client';
import {ApolloLink} from 'apollo-link';
import {HttpLink} from 'apollo-link-http';
import auth0 from 'auth0-js';
import {setContext} from 'apollo-link-context';
import {onError as makeErrorLink} from "apollo-link-error";
import {InMemoryCache} from 'apollo-cache-inmemory';
import React, {Component} from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import {WidgetScreen} from './pages/widgets';
import './App.css';


type State = {
  authToken: string,
  client: any
};


const globalConfig = {
  appUrl: 'http://localhost:3000',
  apiUrl: 'http://localhost:5000/api',
  ...((window as any).CONFIG || {})
}


function getApolloClient(token: string, onError: any) {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
      }
    }
  });

  const errorLink = makeErrorLink(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }
    if (networkError) {
      // XXX Seems impossible to know what kind of error that is, we only
      // get a string...
      // https://github.com/apollographql/apollo-link/issues/218
      console.log(`[Network error]: ${networkError}`);
      onError(networkError);
    }
  });

  const httpLink = new HttpLink({ uri: `${globalConfig.apiUrl}/graphql` });

  const client = new ApolloClient({
    // @ts-ignore
    link: ApolloLink.from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache()
  });

  return client;
}


class App extends Component<{}, State> {
  webAuth: any;

  constructor(props: {}) {
    super(props);
    const webAuth = this.webAuth = new auth0.WebAuth({
      domain: 'feedbackwidget.eu.auth0.com',
      clientID: 'U2DdUXdJTXYoNZO7C7PTbCz4S4A07OQJ',
      responseType: 'token id_token',
      audience: 'https://feedbackwidget.eu.auth0.com/userinfo',
      scope: 'openid',
      redirectUri: globalConfig.appUrl
    });    

    this.state = this.getAuthState(localStorage.idToken);
  }

  componentDidMount() {
    this.checkAuthInHash();
  }


  checkAuthInHash() {
    this.webAuth.parseHash((err: any, authResult: any) => {
      if (err) {
        console.log(err);
        return;
      }

      if (authResult && authResult.accessToken && authResult.idToken) {
         this.setAuth(authResult.idToken);
      } 
      else if (err) {
        console.log(err);
        alert(
          'Error: ' + err.error + '. Check the console for further details.'
        );
      }
    });
  }

  setAuth(token: string|null) {
    localStorage.setItem('idToken', token || "");
    this.setState(this.getAuthState(token || ""));
  }

  getAuthState(token: string) {
    return {
      authToken: token,
      client: token ? getApolloClient(token, this.handleError) : null
    }
  }

  handleError = () => {
    //this.setAuth(null);
  };

  static childContextTypes = {
    logout: T.func
  };

  getChildContext() {
    return {
      logout: this.handleLogout
    }
  }

  render() {
    const isAuth = !!this.state.authToken;

    if (!isAuth) {
      return <div>
        Not logged in. <a href="" onClick={this.handleLogin}>Login</a>
      </div>
    }

    return (
      <ApolloProvider client={this.state.client}>
        <BrowserRouter>
          <Switch>
            <Route path="/widgets" component={WidgetScreen} />
            <Redirect to="/widgets" />
          </Switch>
        </BrowserRouter>
      </ApolloProvider>
    );
  }

  handleLogin = (e: any) => {
    e.preventDefault();
    this.webAuth.authorize();
  }

  handleLogout = () => {
    //this.lock.logout(); - wants to do a redirect?
    this.setAuth(null);
  }
}

export default App;