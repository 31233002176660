import React, {Component} from 'react';
import {AnchorButton, Classes} from "@blueprintjs/core";
import T from 'prop-types';
import './styles.css';


type Props = {
  currentPageLabel: string;
  buttons: any;
  className?: string
};


export class Screen extends Component<Props> {

  static contextTypes = {
    logout: T.func
  };

  render() {
    const {buttons, currentPageLabel, className} = this.props;

    return (
      <div className={`Screen ${className}`}>
        <header className="Header">
          <div className="Header-PageSelector">
            {currentPageLabel}
          </div>
          {buttons && <div className="Header-Buttons">
            {buttons}
          </div>}
          <div className="Header-RightButtons">
            <AnchorButton iconName="help" className={Classes.MINIMAL} href="" onClick={this.handleFeedbackClick}>Feedback</AnchorButton>
            <AnchorButton iconName="log-out" className={Classes.MINIMAL} href="" onClick={this.handleLogout}>Logout</AnchorButton>
          </div>
        </header>
        <div className="Screen-Content">
          {this.props.children}
        </div>
      </div>
    );
  }

  handleLogout = (e: any) => {
    e.preventDefault();
    this.context.logout();
  };

  handleFeedbackClick = () => {
  }
}
