import React, {Component} from 'react';

type Props = {
  widget: any;
}

export default class WidgetInstallPane extends Component<Props> {
  render() {
    return <textarea style={{width: '500px', 'height': '400px'}}>{
          `
<script>
Feedbackwidget=window.Feedbackwidget||[];(function(){var uv=document.createElement('script');uv.type='text/javascript';uv.async=true;uv.id='feedbackwidgetlib';uv.src='{{jslib_url}}/dist/trigger.js';var s=document.getElementsByTagName('script')[0];s.parentNode.insertBefore(uv,s)})();
</script>
<script>
Feedbackwidget.push(['set', {
  widgetId: '${this.props.widget.id}'
}]);
Feedbackwidget.push(['addTrigger', { position: { top: 10, left: 60 } }]);
          `.trim()
        }
        </textarea>
  }
}