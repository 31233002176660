import React, {Component} from 'react';
import {Button} from "@blueprintjs/core";

type Props = {
  widget: any;
}

export default class WidgetInstallPane extends Component<Props> {
  render() {
    const {widget} = this.props;

    return <div>
      <h3>Operators</h3>
      {!widget.operators || !widget.operators.length ? <div>
        <em>No operators configured.</em>
      </div> : null}

      {widget.operators && <ul>
        {widget.operators.map((operator: any) => {
          return <li key={operator}>
            {operator.user.email} ({operator.role})
          </li>
        })}
      </ul>}
    </div>
  }
}