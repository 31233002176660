import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {installPluginAPI} from './Plugins';


installPluginAPI();


ReactDOM.render(<App />, document.getElementById('root'));