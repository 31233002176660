
import React, {Component} from 'react';
import classnames from 'classnames';
import {WidgetListItem} from './WidgetListItem';
import './WidgetList.css';

type Props = {
  widgets: any[];
  onClick: (id: string) => void;

  addNewMode: boolean;
  onAddNewAbort: Function;
  onAddNewComplete: any;
  className?: string;
};


export class WidgetList extends Component<Props> {
  render() {
    const {widgets, addNewMode, onAddNewAbort, onAddNewComplete} = this.props;
    return (
      <div className={classnames("WidgetList", this.props.className)}>
        {addNewMode && <NewWidgetListItem
          onExit={onAddNewAbort}
          onSave={onAddNewComplete}
        />}
        {widgets.map(widget => {
          return <WidgetListItem
            key={widget.id}
            name={widget.name}
            chatCount={widget.chats.count}
            onClick={() => this.props.onClick(widget.id)}
          />
        })}
      </div>
    );
  }
}



class NewWidgetListItem extends Component<{onExit: Function; onSave: Function}> {
  input: any;

  componentDidMount() {
    this.input.focus();
  }

  render() {
    return <WidgetListItem className="NewWidgetListItem">
      <input
        ref={r => this.input = r}
        placeholder="Type name of the new widget, i.e. site name"
        onBlur={this.handleBlur}
        onKeyPress={this.handleKeyPress}
      />
    </WidgetListItem>;
  }

  handleBlur = () => {
    this.props.onExit();
  };

  handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      this.props.onSave(e.target.value);
    }
  }
}
