import React, {Component} from 'react';
import './WidgetListItem.css';
import classnames from 'classnames';


type Props = {
  onClick?: () => void,
  className?: string,
  name?: string,
  chatCount?: number,
};


export class WidgetListItem extends Component<Props> {
  render() {
    return <div
        className={classnames("WidgetListItem", this.props.className)}
        onClick={this.props.onClick}
    >
      {this.props.children || <div>
        {this.props.name}
        <div className="WidgetListItem-info">
          {this.props.chatCount} chats
        </div>
      </div>}
    </div>
  }
}